import classnames from 'classnames';
import { TFunction, useTranslation } from 'next-i18next';
import { RefObject, useEffect, useState } from 'react';

import { IconBack24 } from '../../../icons/arrows/ic-back';
import { IconForth24 } from '../../../icons/arrows/ic-forth';

import ButtonWithIcon, { ButtonWithIconStyle } from './ButtonWithIcon';

export type ScrollState = {
    startReached: boolean;
    endReached: boolean;
};

type BackAndForthButtonOverlayProps = {
    scrollContainerRef: RefObject<HTMLElement>;
    renderOutside?: boolean;
    onScrollStateChange?: (scrollState: ScrollState) => void;
    buttonWithIconStyle?: ButtonWithIconStyle;
    t?: TFunction;
};

function BackAndForthButtonOverlay(props: BackAndForthButtonOverlayProps): JSX.Element {
    const t = props.t || useTranslation(['common']).t;

    const { scrollContainerRef, renderOutside, onScrollStateChange, buttonWithIconStyle } = props;

    const [scrollState, setScrollState] = useState<ScrollState>({
        startReached: true,
        endReached: false
    });

    function onBackClick() {
        scrollContainerRef?.current?.scrollTo({
            left: scrollContainerRef?.current?.scrollLeft - 300,
            behavior: 'smooth'
        });
    }

    function onForthClick() {
        scrollContainerRef?.current?.scrollTo({
            left: scrollContainerRef?.current?.scrollLeft + 300,
            behavior: 'smooth'
        });
    }

    function onScroll() {
        if (scrollContainerRef?.current) {
            const startReached = scrollContainerRef.current.scrollLeft <= 0;
            const endReached =
                scrollContainerRef.current.offsetWidth + scrollContainerRef.current.scrollLeft >=
                scrollContainerRef.current.scrollWidth;
            setScrollState({
                startReached: startReached,
                endReached: endReached
            });
        }
    }

    useEffect(() => {
        const scrollContainer = scrollContainerRef?.current;
        scrollContainer?.addEventListener('scroll', onScroll);
        onScroll();
        return () => {
            scrollContainer?.removeEventListener('scroll', onScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollContainerRef?.current]);

    useEffect(() => {
        onScrollStateChange && onScrollStateChange(scrollState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollState]);

    return (
        <div className="hidden md:flex absolute top-0 w-full h-full items-center justify-between pointer-events-none">
            {scrollState.startReached !== true && (
                <ButtonWithIcon
                    className={classnames('pointer-events-auto', {
                        '-translate-x-48': renderOutside
                    })}
                    icon={<IconBack24 />}
                    onClick={onBackClick}
                    style={buttonWithIconStyle || renderOutside ? ButtonWithIconStyle.transparent : ButtonWithIconStyle.default}
                    accessibilityName={t('previous')}
                />
            )}
            {scrollState.endReached !== true && (
                <ButtonWithIcon
                    className={classnames('ml-auto pointer-events-auto', {
                        'translate-x-48': renderOutside
                    })}
                    icon={<IconForth24 />}
                    onClick={onForthClick}
                    style={buttonWithIconStyle || renderOutside ? ButtonWithIconStyle.transparent : ButtonWithIconStyle.default}
                    accessibilityName={t('next')}
                />
            )}
        </div>
    );
}

export default BackAndForthButtonOverlay;
