import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useRef, useState } from 'react';
import { InfiniteData } from 'react-query';

import ListLoadingSpinner from '@/cineamo-frontend-lib/components/ui/animation/ListLoadingSpinner';
import BackAndForthButtonOverlay, {
    ScrollState
} from '@/cineamo-frontend-lib/components/ui/buttons/BackAndForthButtonOverlay';
import ListPlaceholderSimple from '@/cineamo-frontend-lib/components/ui/list/ListPlaceholderSimple';
import CinemaCard from '@/components/pages/cinema/CinemaCard';
import LinkWithStyle from '@/components/ui/link/LinkWithStyle';

import { CinemaDto } from '@/cineamo-frontend-lib/models/cinema/CinemaDto.types';

import { CinemaListResponse } from '@/cineamo-frontend-lib/api/ApiListResponse.types';
import { ApiResponse } from '@/cineamo-frontend-lib/api/ApiResponse.types';

import { cinemaIdOrSlug } from '@/cineamo-frontend-lib/helper/cinema-helper';
import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';

import { LocaleMiddlewareProps } from '@/cineamo-frontend-lib/middleware/localeMiddleware';

type ChooseCinemaSliderProps = LocaleMiddlewareProps & {
    data: InfiniteData<ApiResponse<unknown, ApiResponse<CinemaListResponse>>>;
    isFetching: boolean;
    hasNextPage?: boolean;
};

function CinemasSlider(props: ChooseCinemaSliderProps, ref): JSX.Element {
    const {
        data: cinemaListResponse,
        isFetching: isFetchingCinemaList,
        hasNextPage: cinemaListResponseHasNextPage,
        locale
    } = props;

    const { t } = useTranslation('slider');

    const [selectedCinema, setSelectedCinema] = useState<CinemaDto>(null);
    const [scrollState, setScrollState] = useState<ScrollState>({
        startReached: true,
        endReached: false
    });
    const cinemaScrollContainerRef = useRef();

    const emptyData = !cinemaListResponse?.pages?.some((page) => page?._embedded?.cinemas?.length > 0);

    return (
        <div className="space-y-24 ">
            <div className="relative w-full flex flex-col">
                {!isFetchingCinemaList && emptyData ? (
                    <ListPlaceholderSimple text={t('landing-page:no-cinemas-found')} />
                ) : (
                    <div
                        className="w-full min-h-225 flex flex-row space-x-24 no-scrollbar items-center overflow-x-auto pb-8"
                        style={styleFaded(
                            scrollState.startReached
                                ? StyleFadeDirection.Right
                                : scrollState.endReached
                                ? StyleFadeDirection.Left
                                : StyleFadeDirection.Horizontal,
                            5
                        )}
                        ref={cinemaScrollContainerRef}>
                        {cinemaListResponse?.pages?.map((page: ApiResponse<CinemaListResponse>) =>
                            page?._embedded['cinemas']?.map((cinema) => (
                                <LinkWithStyle
                                    key={cinema.id}
                                    href={`/cinemas/${cinemaIdOrSlug(cinema)}`}
                                    accessibilityLabel={cinema.name}>
                                    <CinemaCard
                                        cinema={cinema}
                                        selected={cinema?.id === selectedCinema?.id}
                                        onHover={(cinema) => setSelectedCinema(cinema)}
                                        locale={locale}
                                    />
                                </LinkWithStyle>
                            ))
                        )}

                        {cinemaListResponseHasNextPage && (
                            <div
                                className="flex-none loader-element"
                                ref={ref}
                            />
                        )}
                        {cinemaListResponseHasNextPage !== false && (
                            <ListLoadingSpinner
                                className={classnames(
                                    'flex-none pr-24',
                                    { 'opacity-0': !isFetchingCinemaList },
                                    { 'w-auto ': !emptyData }
                                )}
                            />
                        )}
                    </div>
                )}
                <BackAndForthButtonOverlay
                    scrollContainerRef={cinemaScrollContainerRef}
                    onScrollStateChange={(scrollState) => setScrollState(scrollState)}
                />

                <LinkWithStyle href={'/program'}>
                    <div className="flex flex-row space-x-8 items-center clickable-element">
                        <span className="text-cyan text-body-bold-14">{t('landing-page:show-more-cinemas')}</span>
                    </div>
                </LinkWithStyle>
            </div>
        </div>
    );
}

export default React.forwardRef(CinemasSlider);
