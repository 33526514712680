import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/future/image';

import { CinemaDto } from '@/cineamo-frontend-lib/models/cinema/CinemaDto.types';

import { distanceToCinema } from '@/cineamo-frontend-lib/helper/LocationHelper';
import { loadAsset } from '@/cineamo-frontend-lib/helper/asset-helper';
import { getCinemaInitials } from '@/cineamo-frontend-lib/helper/cinema-helper';
import { StyleFadeDirection, styleFaded } from '@/cineamo-frontend-lib/helper/styleHelper';
import { tailwindColors } from '@/src/helper/tailwindConfigHelper';

import { IconCineamoOutline16 } from '@/cineamo-frontend-lib/icons/cineamo/ic-cineamoOutline';
import { IconCineamoSolid16 } from '@/cineamo-frontend-lib/icons/cineamo/ic-cineamoSolid';
import { IconLocation16 } from '@/cineamo-frontend-lib/icons/menu/ic-location';

const cinemaPlaceholderImage = loadAsset('/images/placeholders/im-defaultCinemaImage.png');

type CinemaCardProps = {
    cinema: CinemaDto;
    selected?: boolean;
    onClick?: (cinema) => void;
    onHover?: (cinema) => void;
    showMemberState?: boolean;
    locale?: string;
};

function CinemaCard(props: CinemaCardProps): JSX.Element {
    const { cinema, selected, onClick, onHover, showMemberState } = props;

    const { t } = useTranslation(['cinema']);

    const cinemaPrimaryColor = cinema?.primaryColor || tailwindColors.darkBlue.DEFAULT;

    return (
        <div
            className={classnames('relative flex-none flex flex-row w-400 min-h-225 items-center overflow-visible', '')}
            onMouseOver={() => onHover && onHover(cinema)}>
            <div
                className={classnames(
                    'w-full h-auto bg-opacity-10 rounded-8 overflow-visible',
                    'transform transition ease-in-out duration-150 cursor-pointer',
                    selected ? 'scale-100 shadow shadow-lg shadow-darkBlue/25 bg-white' : 'scale-95'
                )}
                onClick={() => onClick && onClick(cinema)}>
                <div className="relative w-full h-175 rounded-8 overflow-hidden select-none">
                    <Image
                        className="w-full h-175 object-cover"
                        width={800}
                        height={800}
                        src={cinema?.profileImageUrl ? cinema.profileImageUrl : cinemaPlaceholderImage}
                        alt={cinema.name}
                    />
                    <div
                        className="absolute left-0 bottom-0 w-full h-1/2 bg-black"
                        style={styleFaded(StyleFadeDirection.Top, 100)}
                    />
                    <div className="absolute left-0 bottom-0 w-full flex space-x-8 p-8">
                        <div className="relative w-56 h-56">
                            <div
                                className="flex flex-col items-center justify-center w-full h-full rounded-full overflow-hidden"
                                style={{ backgroundColor: cinemaPrimaryColor }}>
                                {cinema?.logoImageUrl ? (
                                    <Image
                                        className="w-full h-full"
                                        src={cinema.logoImageUrl}
                                        width={100}
                                        height={100}
                                        alt={cinema.name + ' Logo'}
                                    />
                                ) : (
                                    <div className="m-auto text-body-bold-20">{getCinemaInitials(cinema)}</div>
                                )}
                            </div>
                            {/* TODO: Maybe we need this later for a cinema-chain logo? */}
                            {/*{cinema.isCustomer &&*/}
                            {/*    <div className="absolute right-0 bottom-0">*/}
                            {/*        <IconCineamoSolid24 className="flex-none"/>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        <div className="text-body-bold-14 my-auto">{cinema.name}</div>
                    </div>
                </div>
                <div
                    className={classnames(
                        'relative transition-all duration-200 ease-in-out overflow-hidden select-none',
                        selected ? 'opacity-100' : 'opacity-0'
                    )}>
                    <div className="text-body-10 p-8 space-y-8">
                        <div className="flex flex-row justify-between">
                            <div className="flex flex-row">
                                <IconLocation16 className="mr-8" />
                                <span>
                                    {cinema.street && cinema.street + ', '}
                                    {cinema.postalCode} {cinema.city}
                                </span>
                            </div>
                            <span>{distanceToCinema(cinema)}</span>
                        </div>
                        {showMemberState && (
                            <div className="flex flex-row">
                                <div className="flex flex-row">
                                    <span className="mr-8">
                                        {cinema._embedded.cinemaConfiguration.isPrivateBookingPartner ? (
                                            <IconCineamoSolid16 />
                                        ) : (
                                            <IconCineamoOutline16 />
                                        )}
                                    </span>
                                    <span>
                                        {cinema._embedded.cinemaConfiguration.isPrivateBookingPartner
                                            ? t('cinema:fullFunctionality')
                                            : t('cinema:notFullFunctionality')}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CinemaCard;
